<template>
    <div class="cont-cla">        
        <div class="search-list">
            <div class="list-al">状态：
                <Select v-model="searchData.type" @on-change="goClear" clearable style="width: 200px" placeholder="请选择订单状态">
                    <Option v-for="item in typeList" :value="item.id" :key="item.id">{{ item.label }}</Option>
                </Select>
            </div>
            <div class="list-al">
                <Button @click="searchList()"   style="background:#4877E8;color:#fff;border-color:#4877E8">搜索</Button>
            </div>
        </div>
        <div class="table-body">           
            <Table :columns="columnTable" :data="dataTable" :loading='loadingTab'>             
                <template slot-scope="{ row }" slot="coupon">
                    {{ row.coupon.name}}
                </template>     
                <!-- data.data.member.name -->
                <template slot-scope="{ row }" slot="member">
                    {{ row.member.name}}
                </template>         
                <template slot-scope="{ row }" slot="number">
                    {{ row.member.number}}
                </template>  
                <template slot-scope="{ row }" slot="account">
                    {{ row.member.account}}
                </template>            
            </Table>
            <Page :total="total" :current="searchData.page" style="text-align:right;margin-top: 20px" show-elevator
                show-total @on-change="pageChange" :page-size="searchData.limit" />
        </div>       
        
    </div>
</template>

<script>
import { withdrawal_list,withdrawal_examine } from "@/api/index";
export default {
    data() {
        return {
            timer: '',
            submitData:{
                id:'',
                type:1,
                application_id:'',
                title: '',
            },
            showfwm:false,
            formTitle: 'add',
            loading: false,
            total: 0,
            timeVal: [],
            loadingTab: false,
            dataTable: [],         
            searchData: {           
                page: 1,
                type: '',
                limit: 10,
                is_history:1
            },
            submitformdata:{
                reason: '',
                id: '',
                status:2,
            },
            typeList: [
                { id: -1, label: '所有' },
                { id: 1, label: '已通过' },
                { id: 2, label: '已拒绝' },
            ],            
            total_num: 0,            
            systemList: [],
            columnTable: [                
                {
                    title: '序号',
                    key: 'id',
                    align: 'center',
                    width: 80
                },
                // data.data.member.name
                {
                    title: '会员名称',
                    slot: 'member',
                    align: 'center'
                }, 
                {
                    title: '会员账号',
                    slot: 'account',
                    align: 'center'
                },
                {
                    title: '会员编号',
                    slot: 'number',
                    align: 'center'
                }, 
                {
                    title: '金额',
                    key: 'money',
                    align: 'center'
                },
                {
                    title: '状态',
                    key: 'status',
                    align: 'center'
                }, 
                {
                    title: '拒绝原因',
                    key: 'reason',
                    align: 'center'
                },                 
                {
                    title: '日期',
                    key: 'updated_at',
                    align: 'center'
                }
            ]
        }
    },
    created() {
        this.get_list()
    },
    methods: {       
        searchList() {
            this.searchData.page = 1
            this.get_list()
        },
        get_list() {
            this.loading = true
            this.loadingTab = true
            withdrawal_list(this.searchData).then(res => {
                this.dataTable = res.data.data
                this.total = res.data.total
                this.loading = false
                this.loadingTab = false
            }).catch(res => {
                this.$Message.error(res.msg)
                this.loadingTab = false
                this.loading = false
            })
        },
        changeTime(e) {
            this.searchData.start_time = e[0]
            this.searchData.end_time = e[1]
        },
        pageChange(index) {
            this.searchData.page = index
            this.get_list()
        },
        addfwm(){
            if(!this.submitformdata.reason){
                return this.$Message.error('请填写拒绝理由')
            }
            withdrawal_examine(this.submitformdata).then(res => {
                this.$Message.success(res.msg)
                this.get_list()
                this.showfwm = false
            }).catch(err => {
                this.$Message.error(err.msg)
                this.get_list()
            })
        },
        goRefund(e){
            this.submitformdata.id = e.id
            this.submitformdata.reason = ''
            this.showfwm = true
        },
        withdrawal_examine(e) {
            this.$Modal.confirm({
                title: '提示',
                content: '是否同意同意该订单提现请求？',
                onOk: () => {            
                    withdrawal_examine({ id: e.id,status:1,reason:'' }).then(res => {
                        this.$Message.success(res.msg)
                        this.get_list()
                    }).catch(err => {
                        this.$Message.error(err.msg)
                        this.get_list()
                    })
                },
                onCancel: () => {
                }
            });
        },
        goClear(e){
            console.log('e',e)
            if(!(e || e == 0)){
                this.searchData.status = ''
            }
        },
        closeMod() {
            this.showPay = false
            clearInterval(this.timer)
        }        
    }


}
</script>

<style scoped lang="scss">
::v-deep .ivu-modal-header-inner {
    font-weight: 500 !important;
}

::v-deep .ivu-modal-body {
    padding: 16px 0px !important;
}

.table-body {
    width: 100%;
    height: 100%;
    background: #ffffff;
    margin: 20px 0px;
    padding: 10px 30px;
}

.cont-cla {
    width: 100%;
    height: 100%;
}


.search-list {
    width: 100%;
    padding: 20px 30px;
    padding-bottom: 20px;
    color: #545F78;
    background: #ffffff;
    font-family: PingFang SC;
    // margin-top: 20px;
    display: flex;
    line-height: 32px;
    word-break: keep-all;
    white-space: nowrap;
    flex-wrap: wrap;
}
.list-al {
    display: flex;
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}


.flex-s {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 28%;
}

::v-deep .ivu-modal-header p {
    font-weight: 900
}

::v-deep .ivu-modal-header-inner {
    font-weight: 900
}

::v-deep .bottom-swip img {
    // width: 75%;
    // height: 75%;
    max-width: 100%;
    max-height: 100%;
    padding: 0px 16px // margin-left: 10%;
}
::v-deep.canshowcont {
    .ivu-modal-close {
        display: none;
    }
}
</style>

<style>
.demo-spin-icon-load {
    animation: ani-demo-spin 3s linear infinite;
}

@keyframes ani-demo-spin {
    from {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.demo-spin-col {
    top: 80px;
    left: 80px;
    position: relative;
    /* // border: 1px solid #eee; */
}
</style>